export default {
  data: () => ({
    pageInactiveCONST : 'pageInactiveStartTime',
    refreshScriptFlagCONST: 'refreshScriptFlag',
    pageInactiveRefreshCONST : 59,
  }),
  methods: {
    getParameterByName: function(name) {
      var url = window.location.href;
      name = name.replace(/[\[\]]/g, '\\$&');
      var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
          results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return 'exist';
      return decodeURIComponent(results[2].replace(/\+/g, ' '));
    },
    isNeedToReload: function() {
      var startTime = localStorage.getItem(this.pageInactiveCONST) != null ? localStorage.getItem(this.pageInactiveCONST) : new Date();
      var pageInactiveStartTime = new Date(startTime);
      var seconds = (new Date().getTime() - pageInactiveStartTime.getTime())/1000;
      return Math.floor(seconds) > this.pageInactiveRefreshCONST ? true : false; // 1 min
    },
    setRefreshScriptFlag: function() {
      localStorage.setItem(this.refreshScriptFlagCONST, true);
    },
    reloadPage: function() {
      window.location.reload(true);
      //window.history.go(0);
    },
    refreshPage: function() {
      if (this.isNeedToReload()) {
        this.setRefreshScriptFlag();
        this.reloadPage();
      }
    },
    setPageInactiveTime: function() {
      localStorage.setItem(this.pageInactiveCONST, new Date());
    },
    initializePageRefresh: function() {
     if(typeof localStorage !== 'undefined') {
        var self = this;
        
        window.onblur = () => {
          self.setPageInactiveTime();
        };

        window.onfocus = () => {
          self.refreshPage();
        }
      }
    },
  },
  mounted() {
    if (this.getParameterByName('ignoreRefresh') && 
    (this.getParameterByName('ignoreRefresh') == 'exist' || this.getParameterByName('ignoreRefresh').toString().toLowerCase() == 'true')) {
      // do Nothing
    } else {
      this.initializePageRefresh();
    }
  },
};
