//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';
import axios from 'axios';

export default {
  name: 'IpLoginPopUp',
  data() {
    return {
      showIpLogin: false
    }
  },
  computed: {
    ...mapState(['locals', 'loginStatus'])
  },
  errorCaptured: function (err) {
    console.error(`Caught error from children of IpLoginPopup component:: ${err.message}`);
  },
  mounted() {
    const app = this;
    const { isLoggedIn, isSubscriber } = app.loginStatus;
    const isNonLoggedInOrPassiveUser = !isLoggedIn || !isSubscriber;
    const shouldCheckIplogin = app.shouldCheckIplogin(isNonLoggedInOrPassiveUser);
    window.nhst.shouldCheckIplogin = shouldCheckIplogin;

    window.onload = async () => {
      if(shouldCheckIplogin) {
        try{
          const response = await axios.get(`https://${location.host}/bli-abonnent/api/v1/ip-login/lightbox`);
          const result = response.data;
          if (result && result.url && !result.agreement_full) {
              //If lightbox is not shown already
              if (typeof localStorage !== 'undefined') {
                const iploginIframe = document.querySelector('#iplogin-modal .iplogin-modal-iframe');

                window.addEventListener('message', function (event) {
                  const data = event.data;
                  if ((event.origin.indexOf('.dn.no') > -1) && data['iframe-height']) {
                    iploginIframe.style.height = `${data['iframe-height']}px`;
                  }
                  if (data && data.type && data.type === 'close-ip-popup') {
                    app.showIpLogin = false;
                  }
                });
                iploginIframe.setAttribute('src', result.url);
                app.showIpLogin = true;
              }
            }
            localStorage.setItem('ip-login-modal-last-check', new Date().getTime());
        } catch(err) {
          console.log(`Caught error from IpLoginPopup onload:: ${err.message}`);
        }
      }
    }
  },
  methods: {
    shouldCheckIplogin(isNonLoggedInOrPassiveUser) {
      //If passive user
      if (isNonLoggedInOrPassiveUser) {

        // If lightbox has been shown no need to make the call
        if (typeof (localStorage) !== 'undefined') {
          var now = new Date();
          var lastCheck = localStorage.getItem('ip-login-modal-last-check');
          // Set check interval to 2 days
          var checkInverval = (48 * 60 * 60 * 1000);

          // Set default value for last check time diff. It needs to be bigger than checkInterval
          var milisecondsSinceLastCheck = checkInverval + 1;

          // If we have lastCheck value then re-calculate last check time diff
          // Note that we are working with timestamps since localStorage can store
          // only strings
          if (lastCheck) {
            milisecondsSinceLastCheck = Math.abs(now.getTime() - parseInt(lastCheck));
          }

          // If the time difference of last check and current time is more than 48 hours
          if (milisecondsSinceLastCheck > checkInverval) {
            return true;
          }
        }
        return false;
      }
      return false;
    }

  }
}
