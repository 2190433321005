import { mapState } from 'vuex';
import { isEmpty } from 'lodash';

export default {
  computed: {
    ...mapState(['meta'])
  },
  head() {
    const metas = [
      { name: 'description', content: this.meta.leadText },
      { property: 'og:title', content: this.meta.pageTitle},
      { property: 'og:type', content: this.meta.type },
      { property: 'og:url', content: this.meta.ogUrl },
      { property: 'og:description', content: this.meta.leadText},
      { property: 'og:image', content: this.meta.ogImage },
      { property: 'og:image:secure_url', content: this.meta.ogImageSecureUrl },
      { property: 'og:site_name' , content: this.meta.ogSiteName },

      // facebook metas
      { name: 'fb:app_id', content: this.meta.fb_ap_Id },
      { name: 'fb:pages', content: this.meta.fb_pages },

      // twitter metas
      { name: 'twitter:card' , content: this.meta.twitter_card },
      { name: 'twitter:site' , content: this.meta.twitter_site },
      { name: 'twitter:title' , content: this.meta.pageTitle },
      { name: 'twitter:description', content: this.meta.twitter_description },
      { name: 'twitter:creator', content: this.meta.twitter_creator },
      { name: 'twitter:image:src', content: this.meta.twitter_image_src },
      { name: 'twitter:domain' , content: this.meta.twitter_domain },
      { property: 'article:section', content: this.meta.sectionName },
      { property: 'article:published_time', content: this.meta.published_at },
      { property: 'article:modified_time', content: this.meta.updated_at },
    ]
    return {
      title: this.meta.pageTitle,
      meta: this.createMetaArray(metas)
    };
  },
  methods: {
    createMetaArray(metas) {
      let arr = [];
      metas.filter(val => !(isEmpty(val.content))).forEach(val => {
        const metaObj = {
          hid: val.property || val.name,
          ...val
        }
        arr.push(metaObj);
      });
      return arr;
    }
  }
}
