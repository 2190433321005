//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapState} from 'vuex';
import TopEditionPlaceholderMixin from '../../components/mixin/TopEditionPlaceholderMixin';
import PlaceHolderAdapter from '../../components/component/PlaceHolderAdapter';
import Vue from 'vue';
import LazyHydrate from 'vue-lazy-hydration';

Vue.component('PlaceHolderAdapter', PlaceHolderAdapter);
Vue.component('LazyHydrate', LazyHydrate);

export default {
  mixins: [TopEditionPlaceholderMixin],
  data: () => ({
    topEditionHeight: 0,
  }),
  head() {
    const frontPageTopCssContent = this.topEditionResponse.frontpageTopCssContent;
    let headContent = {};
    if(frontPageTopCssContent && frontPageTopCssContent.trim()) {
      headContent.style = [
        {
          innerHTML: frontPageTopCssContent,
          type: 'text/css'
        }
      ];
      headContent.__dangerouslyDisableSanitizers = ['style'];
    } else {
      headContent.link = [
        {
          rel: 'stylesheet',
          href: this.topEditionResponse.frontpageCssLink,
        },
      ]
    }
    return headContent;
  },
  errorCaptured: function (err) {
    console.error(`Caught error from children of FrontPageTopEdition component:: ${err.message}`);
  },
  computed: {
    ...mapState(['topEditionResponse', 'placeholdersAds']),
    processedHtml() {
      return {
        template: this.topEditionResponse.frontpageContent,
      };
    },
    frontBottomStickyMBox() {
      return (this.topEditionResponse && this.topEditionResponse.adobeMboxConfig && this.topEditionResponse.adobeMboxConfig.frontBottomStickyMBox) || '';
    },
    leftStickySlots() {
      const leftStickyAds = [];
      for (const [, value] of Object.entries(this.placeholdersAds)) {
        if (value.stickyColumn === 'left') {
          leftStickyAds.push(value);
        }
      }
      return leftStickyAds;
    },
    rightStickySlots() {
      const rightStickyAds = [];
      for (const [, value] of Object.entries(this.placeholdersAds)) {
        if (value.stickyColumn === 'right') {
          rightStickyAds.push(value);
        }
      }
      return rightStickyAds;
    }
  },
  mounted() {
    this.getTopEditionHeight();
  },
  updated() {
    this.getTopEditionHeight();
  },
  methods: {
    getTopEditionHeight() {
      this.topEditionHeight = this.$el.clientHeight;
    },
    stickyAdsInlineStyle(adConfig) {
      const bottom = adConfig.endingPixel === 0 || this.topEditionHeight < adConfig.endingPixel ? '0' : `calc(100% - ${adConfig.endingPixel}px`;
      return `bottom: ${bottom}; top: ${adConfig.startingPixel}px`;
    },
    removeOutsideStickyAd(adConfig) {
      return this.topEditionHeight < adConfig.startingPixel ? 'hidden' : '';
    }
  }
};
