import { render, staticRenderFns } from "./_category.vue?vue&type=template&id=f56c6ee0&"
import script from "./_category.vue?vue&type=script&lang=js&"
export * from "./_category.vue?vue&type=script&lang=js&"
import style0 from "./_category.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ComponentIpLoginPopUp: require('/app/components/component/IpLoginPopUp.vue').default,ComponentTopEditionShowOnTopPlaceholders: require('/app/components/component/TopEditionShowOnTopPlaceholders.vue').default,BlockFrontPageTopEdition: require('/app/components/block/FrontPageTopEdition.vue').default,BlockFrontPageBottomEdition: require('/app/components/block/FrontPageBottomEdition.vue').default})
