export default {
  data: () => ({
    topEditionPlaceholderComponentMap: [
      'lantern_placeholder_ad',
      'lantern_placeholder_norkon_and_investor_news',
      'lantern_placeholder_dn_job_portal-front',
      'lantern_placeholder_subscriber-engagement',
      'lantern_placeholder_dn_real_estate_robot-front',
      'lantern_placeholder_everviz-embed-1',
      'lantern_placeholder_everviz-embed-2',
      'lantern_placeholder_dnx-front-element',
      'lantern_placeholder_dnx-front-mixed-element',
      'lantern_placeholder_meninger-front',
      'lantern_placeholder_live-centers-1',
      'lantern_placeholder_live-centers-2',
      'lantern_placeholder_wine_search_embed',
      'lantern_placeholder_most-read',
      'lantern_placeholder_fantasyfond',
      'lantern_placeholder_embed_content',
      'lantern_placeholder_embed_content_2'
    ],
    mapConfig: {
      lantern_placeholder_ad: {
        ClientOnly: true,
        ComponentName: 'place-holder-adapter',
      },
      lantern_placeholder_norkon_and_investor_news: {
        ComponentName: 'place-holder-adapter',
        lazyHydrate: {
          option: 'when-visible'
        }
      },
      'lantern_placeholder_dn_real_estate_robot-front': {
        ComponentName: 'place-holder-adapter',
        lazyHydrate: {
          option: 'when-visible'
        }
      },
      'lantern_placeholder_dn_job_portal-front': {
        ComponentName: 'place-holder-adapter',
        lazyHydrate: {
          option: 'when-visible'
        }
      },
      'lantern_placeholder_subscriber-engagement': {
        ComponentName: 'place-holder-adapter',
        lazyHydrate: {
          option: 'when-visible'
        }
      },
      'lantern_placeholder_everviz-embed-1': {
        ComponentName: 'place-holder-adapter',
        lazyHydrate: {
          option: 'when-visible'
        }
      },
      'lantern_placeholder_everviz-embed-2': {
        ComponentName: 'place-holder-adapter',
        lazyHydrate: {
          option: 'when-visible'
        }
      },
      'lantern_placeholder_dnx-front-element': {
        ComponentName: 'place-holder-adapter',
        lazyHydrate: {
          option: 'when-visible'
        }
      },
      'lantern_placeholder_dnx-front-mixed-element': {
        ComponentName: 'place-holder-adapter',
        lazyHydrate: {
          option: 'when-visible'
        }
      },
      'lantern_placeholder_meninger-front': {
        ComponentName: 'place-holder-adapter',
        lazyHydrate: {
          option: 'when-visible'
        }
      },
      'lantern_placeholder_live-centers-1': {
        ComponentName: 'place-holder-adapter',
        lazyHydrate: {
          option: 'when-visible'
        }
      },
      'lantern_placeholder_live-centers-2': {
        ComponentName: 'place-holder-adapter',
        lazyHydrate: {
          option: 'when-visible'
        }
      },
      lantern_placeholder_wine_search_embed: {
        ComponentName: 'place-holder-adapter',
        lazyHydrate: {
          option: 'when-visible'
        }
      },
      'lantern_placeholder_most-read': {
        ComponentName: 'place-holder-adapter',
        lazyHydrate: {
          option: 'when-visible'
        }
      },
      'lantern_placeholder_fantasyfond': {
        ComponentName: 'place-holder-adapter',
        lazyHydrate: {
          option: 'when-visible'
        }
      },
      'lantern_placeholder_embed_content': {
        ComponentName: 'place-holder-adapter',
        ClientOnly: true,
        lazyHydrate: {
          option: 'when-visible'
        }
      },
      'lantern_placeholder_embed_content_2': {
        ComponentName: 'place-holder-adapter',
        ClientOnly: true,
        lazyHydrate: {
          option: 'when-visible'
        }
      }
    }
  }),
  errorCaptured(err, vm, info) {
    const message = `Something went wrong at ${vm.$options._componentTag}, error: ${err.toString()}, info: ${info}`;
    console.log(message)
    return false;
  }
};
