//
//
//
//
//
//
//
//

import {mapState} from 'vuex';
import PlaceHolderMixin from '@/components/mixin/PlaceHolderMixin';

export default {
  name: 'PlaceHolderAdapter',
  mixins: [PlaceHolderMixin],
  props: {
    id: {
      type: String,
      default: 'empty_placeholder'
    },
    showOnTop: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({}),
  errorCaptured: function(err) {
    console.error(`Caught error from children of PlaceholderAdapter component:: ${err.message}`);
  },
  computed: {
    placeholder() {
      return this.topEditionResponse.placeholders[this.id] || {};
    },
    ...mapState(['topEditionResponse']),
  }
}
