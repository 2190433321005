import {mapActions, mapState} from 'vuex';

export default {
  data: () => ({}),
  computed: {
    ...mapState(['nodeEnv', 'header'])
  },
  methods: {
    extractLoginInfo(cookieValue) {
      if (cookieValue) {
        const decodedCookieValue = decodeURIComponent(escape(window.atob(cookieValue)));
        const loginInfo = decodedCookieValue.split('|');

        if (loginInfo != null && loginInfo.length > 1) {
          return {
            username: loginInfo[3],
            firstName: loginInfo[5],
            lastName: loginInfo[6],
            ncpId: loginInfo[9],
            hasTotal: loginInfo[10]
          };
        }
      }
      return null;
    },
    extractInvestorInfo(cookieValue) {
      if (cookieValue) {
        const decodedCookieValue = decodeURIComponent(escape(window.atob(cookieValue)));
        const investorInfo = decodedCookieValue.split('|');
        if (investorInfo != null && investorInfo.length >= 1) {
          return JSON.parse(investorInfo[0]);
        }
        return decodedCookieValue;
      }
      return null;
    },
    readCookie(name) {
      const nameEQ = name + '=';
      const ca = document.cookie.split(';');
      for (const da of ca) {
        let c = da;
        while (c.charAt(0) === ' ')
          c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0)
          return c.substring(nameEQ.length, c.length);
      }
      return null;
    },
    setCookie(cname, cvalue, exdays) {
      var d = new Date();
      d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
      var expires = 'expires=' + d.toUTCString();
      document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
    },
    getUserSubscriptionProducts() {
      try {
        const cookieName = 'DN_Investor';
        const decodedDnInvestorCookie = decodeURIComponent(escape(window.atob(this.readCookie(cookieName))));
        const dnInvestor = JSON.parse(decodedDnInvestorCookie.split('|sig=')[0]);
        return dnInvestor.products;
      } catch (e) {
        console.log('Could not figure out subscription products');
      }
      return [];
    },
    ...mapActions([
      'setLoginStatus'
    ]),
  },
  created() {
    if (process.client) {
      const oneIdInfoCookieValue = this.readCookie('oneid_info');
      let loginInfo = this.extractLoginInfo(this.readCookie('oneid_info'));
      let investorInfo = this.extractInvestorInfo(this.readCookie('DN_Investor'));
      const products = this.getUserSubscriptionProducts();
      const isSubscriber = ((products || []).length) > 0;
      const isLoggedIn = !!oneIdInfoCookieValue;
      loginInfo = loginInfo || {};
      investorInfo = investorInfo || {};
      const isTotalSubscriber = (loginInfo.hasTotal && loginInfo.hasTotal === 'true');

      this.setLoginStatus({
        isLoggedIn,
        username: loginInfo.username || '',
        firstName: loginInfo.firstName || '',
        lastName: loginInfo.lastName || '',
        ncpId: loginInfo.ncpId || '',
        ncpIdHash: investorInfo.ncpid || '',
        products,
        isSubscriber,
        isTotalSubscriber
      })
    }
  }
};
