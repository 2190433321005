import {mapState} from 'vuex';

export  default {
  computed: {
    ...mapState(['meta', 'nodeEnv', 'loginStatus', 'topEditionResponse', 'isShowingOnMobileApp'])
  },
  beforeMount() {
    this.populateOldDataLayer();
    this.populateDataLayer();
  },
  methods: {
    populateOldDataLayer() {
      window.nhst = window.nhst || {};
      if (window._satellite?.cookie !== undefined && window._satellite?.cookie !== null && typeof window.nhst.aamSegments === 'undefined') {        let aamCookie = window._satellite.cookie.get('aam');
        if (aamCookie !== null) {
          let rawSegments = /(^|[,;])segments=([^,;$]+)/.exec(decodeURIComponent(aamCookie));
          if (rawSegments != null && rawSegments.length === 3) {
            window.nhst.aamSegments = rawSegments[2];
          } else {
            window.nhst.aamSegments = '';
          }
        }
      } else {
        window.nhst.aamSegments = '';
      }
    },
    populateDataLayer(){
      window.nhstDataLayer = window.nhstDataLayer || {};
      window.nhstDataLayer.events = window.nhstDataLayer.events || [];

      window.nhstDataLayer.environmentInfo = {
        env: this.nodeEnv,
        view: this.isShowingOnMobileApp ? 'app' : 'web',
        publication: 'dn'
      };

      window.nhstDataLayer.page = {
        title: this.meta.pageTitle,
        availability: 'free',
        paywall: false,
      };

      let publishUnixFormatDate = new  Date(this.meta.published_at).getTime();
      let modifyDateUnixFormatDate = new  Date(this.meta.updated_at).getTime();

      window.nhstDataLayer.content = {
        id: this.topEditionResponse.id,
        contentType: 'frontpage',
        publishDate: publishUnixFormatDate,
        modifyDate: modifyDateUnixFormatDate,
        authors: ['']
      };

      let currentDateTime = new Date().getTime()
      window.nhstDataLayer.events.push(
        {'event' : 'DATALAYER_PAGE_LOADED_READY','timestamp' : currentDateTime},
      )
    }
  }
}
