//
//
//
//
//
//
//

import { mapState } from 'vuex';
import TopEditionPlaceholderMixin from '../mixin/TopEditionPlaceholderMixin';
import PlaceHolderAdapter from './PlaceHolderAdapter';

export default {
  name: 'TopEditionShowOnTopPlaceholders',
  components: {
    PlaceHolderAdapter
  },
  mixins: [TopEditionPlaceholderMixin],
  computed: {
    ...mapState(['topEditionResponse']),
    showOnTopPlaceholderIds() {
      return this.topEditionResponse.showOnTopPlaceholderIds || [];
    },
    showOnTopPlaceholdersHtml() {
      let placeholderHtml = '';
      const topEditionPlaceholders = this.topEditionResponse.placeholders || {};
      for(const topPlaceholderId of this.showOnTopPlaceholderIds) {
        if (
          this.topEditionPlaceholderComponentMap.indexOf(topEditionPlaceholders[topPlaceholderId].placeholder) > -1
        ) {
          const placeholderConfig = this.mapConfig[topEditionPlaceholders[topPlaceholderId].placeholder];
          let placeholderStr = `<${placeholderConfig.ComponentName} id="${topEditionPlaceholders[topPlaceholderId].id}" showOnTop></${placeholderConfig.ComponentName}>`;
          if (placeholderConfig.ClientOnly) {
            placeholderStr = `<client-only>${placeholderStr}</client-only>`;
          } else if(placeholderConfig.lazyHydrate && placeholderConfig.lazyHydrate.option) {
            placeholderStr = `<lazy-hydrate ${placeholderConfig.lazyHydrate.option}>${placeholderStr}</lazy-hydrate>`
          }
          placeholderHtml += placeholderStr;
        } else {
          console.log(
            'No component found for placeholder -> ',
            JSON.stringify(topPlaceholderId)
          );
        }
      }
      return {
        template: `<div>${placeholderHtml}</div>`,
      };
    }
  }

}
